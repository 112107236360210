<template>
  <div class="title-container">
    <div class="img-box">
      <img v-if="type == 1" src="@assets/images/area_hd.png" alt="">
      <img v-else src="@assets/images/statistic_hd.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
    }
  }
}
</script>

<style lang="stylus">
.title-container
  position relative
  margin 0 auto
  width 80%
  z-index 7
  img
    width 100%
</style>


