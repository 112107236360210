<template>
  <div class="stat-container">
    <Header type="1" />
    <div class="map-container">
      <div id="map-box" ref="map" class="map-box"></div>
    </div>
    <div class="main-container">
      <div class="table-box">
        <div class="table-header">
          <DatePicker
            type="month"
            @on-change="handleDateChange"
            placeholder="请选择年月"
            class="picker-box"
            size="large"
            style="width: 300px"
          />
          <Button
            @click="handleSearchClick"
            class="btn-search"
            icon="ios-search"
            size="large"
            type="primary"
          >
            查询
          </Button>
        </div>
        <transition name="fade-transform">
          <div class="table-body" v-if="!showProvinceDetail">
            <div class="table-title">
              <h3>项目分布信息</h3>
            </div>
            <div class="table-main">
              <div class="row-item">
                <div class="province-box">省份</div>
                <div class="num-box">项目数量</div>
                <div class="bz-box">班组数量</div>
                <div class="bz-num-box">班组人员数量</div>
              </div>
              <div
                class="row-item row-hover"
                v-for="(item, index) in projectTeamInfos"
                :key="index"
                @click="handleProvinceClick(item)"
              >
                <div class="province-box">{{ item.province }}</div>
                <div class="num-box">{{ item.totalProjects || 0 }}</div>
                <div class="bz-box">{{ item.totalTeams || 0 }}</div>
                <div class="bz-num-box">
                  {{ item.totalTeamItem || 0 }}
                  <i class="iconfont icon-Right"></i>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade-transform" mode="out-in">
          <div class="table-body" v-if="showProvinceDetail">
            <div class="table-title">
              <h3 class="title-box">{{pName}}项目班组培训情况</h3>
              <div @click="handleBackClick">
                <i class="iconfont icon-Left" />
                返回
              </div>
            </div>
            <div class="table-main p-main">
              <div class="row-item">
                <div
                  class="item-box"
                  v-for="head in columnsT"
                  :key="head.key"
                >
                  {{ head.title }}
                </div>
              </div>
              <div
                class="row-item row-hover"
                v-for="(item, index) in taskInfos"
                :key="index"
                @click="handleRowClick(item)"
              >
                <div class="item-box">{{ item.projectName }}</div>
                <div class="item-box">{{ item.totalTasks || 0 }}</div>
                <div class="item-box">{{ item.totalTaskTeamItems || 0 }}</div>
                <div class="item-box">{{ item.totalTaskTeamItemsTimes || 0 }}</div>
                <div class="item-box">{{ item.totalTeamItemTimes || 0 }}</div>
                <div class="item-box">{{ item.finishTaskTeamItemsTimes || 0 }}</div>
                <div class="item-box">
                  {{ item.finishRates || 0 }}
                  <i class="iconfont icon-Right"></i>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- <div class="main-section">
      <h3>项目班组培训情况</h3>
      <Table
        class="table-team"
        :row-class-name="rowClassName"
        :columns="columnsT"
        :data="taskInfos"
        @on-row-click="handleRowClick"
      />
    </div> -->
  </div>
</template>

<script>
import echarts from 'echarts'
import 'echarts/map/js/china.js'
import 'echarts/extension/bmap/bmap'
import Header from './components/TheHeader'
// import 'echarts/extension/bmap/bmap.js'
import columns from './columns.js'

export default {
  name: "Statistics",
  data() {
    return {
      columnsP: columns.columnsP,
      columnsT: columns.columnsT,
      dateStr: null,  // 选择年月
      yearStr: "2019",
      monthStr: "1",
      taskInfos: [],
      projectTeamInfos: [],
      provinces: [],
      pCode: '',
      pName: '',
      showProvinceDetail: false,
      list: []
    };
  },
  components: {
    Header,
  },
  created() {
    this.getProjectTeamInfo();
  },
  mounted() {},
  methods: {
    mapInit(list) {
      var that = this
      var dataList = list;
      var myChart = echarts.init(document.getElementById("map-box"));
      var option = {
        tooltip: {
          formatter: function(params, ticket, callback) {
            const val = params.value || 0;
            return (
              params.seriesName + "<br />" + params.name + "：" + val
            );
          } //数据格式化
        },
        visualMap: {
          min: 0,
          max: 99999,
          left: "left",
          top: "bottom",
          text: ["高", "低"], //取值范围的文字
          inRange: {
            color: ["#02c7d3", "#e0ffff", "#060D2A"] //取值范围的颜色
          },
          show: false //图注
        },
        geo: {
          map: "china",
          roam: false, //不开启缩放和平移
          zoom: 1.1, //视角缩放比例
          left: 60,
          label: {
            normal: {
              show: true,
              fontSize: "10",
              color: "#fff"
            }
          },
          itemStyle: {
            normal:{
              color:'red',
              borderColor:'rgba(100,149,237,1)',
              borderWidth:0.5,
              areaColor: '#060D2A', //'#f0f0f0',  // 背景色设置
              areaStyle:{
                color: '#fff'  // 字体颜色设置
              }
            },
            // normal: {
            //   borderColor: "rgba(0, 0, 0, 0.2)",
            //   areaStyle: { color: "blue" }
            // },
            emphasis: {
              areaColor: "#F3B329", //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        },
        series: [
          {
            name: "项目数量",
            type: "map",
            mapType: "ningbo",
            geoIndex: 0,
            data: dataList
          }
        ]
      };
      myChart.setOption(option);
      myChart.on("click", function(params) {
        that.pCode = params.data.code
      });
    },
    handleBackClick() {
      this.showProvinceDetail = false
      this.pCode = ''
      this.pName = ''
    },
    handleProvinceClick(row) {
      this.showProvinceDetail = true
      this.pCode = row.provinceCode
      this.pName = row.province
    },
    handleRowClick(row) {
      this.$router.push({
        name: 'charts',
        query: {
          pId: row.projectId
        }
      })
      
    },
    handleDateChange (e) {
      this.dateStr = e ? e : null
    },
    handleSearchClick() {
      this.getProjectTeamInfo();
    },
    async getProjectTeamInfo() {
      let info = this.common.getStorage("user_info")
      if(!info) {
        this.$router.push({ name: 'login' })
      }
      const date = this.dateStr && this.dateStr.split("-");
      const reqData = {
        icorpid: info.icorpid
      }
      if(date && date[1]) {
        reqData.monthStr = date && date[1]
      }
      if(date && date[0]) {
        reqData.yearStr = date && date[0]
      }
      if(this.pCode) {
        reqData.province = this.pCode
      }

      reqData.phone = info.login_id;

      let res = await this.$fetch('/sapi/corpStat/getProjectTeamInfo', {
        method: "post",
        data: reqData
      });
      if (res) {
        const { taskInfos, projectTeamInfos } = res.data;
        this.provinces = projectTeamInfos.map(p => {
          let pName = p.province
          if(pName) {
            if(pName.indexOf('省') > 0) {
              pName = pName.split('省')[0]
            } else if(pName.indexOf('市') > 0) {
              pName = pName.split('市')[0]
            }
          }
          return {
            name: pName,
            value: p.totalProjects,
            code: p.provinceCode
          }
        });
        this.taskInfos = taskInfos;
        this.projectTeamInfos = projectTeamInfos.filter(p => !!p.province);
        this.mapInit(this.provinces);
      }
    }
  },
  watch: {
    pCode(newC, oldC) {
      // 省份code监听
      if (newC !== oldC) {
        this.getProjectTeamInfo()
      }
    }
  },
};
</script>

<style lang="stylus">
td(cl)
  background cl
  color #fff
  border none

.stat-container
  position relative
  padding-bottom 30px
  background-color #0868C9
  color #fff
  .map-container
    position relative
    margin-top -90px
    width 100%
    height 96vh
    z-index 6
    .map-box
      margin 0 auto
      max-width 96%
      width 96%
      height inherit
      margin-left 5%
  .main-container
    position fixed
    top 40px
    right 0
    display flex
    justify-content flex-end
    z-index 6
    .table-box
      position relative
      margin-right 20px
      padding-bottom 20px
      .table-header
        display flex
        justify-content flex-end
        margin-bottom 30px
        padding-top 60px
        .picker-box
          width 300px
          color initial
        .btn-search
          margin-left 15px
          background #0047A8
          opacity 0.85
          
      .table-body
        position absolute
        top 120px
        right 20px
        padding-bottom 20px
        max-height 72vh
        background rgba(0, 71, 168, 0.85)
        overflow-y scroll
        .table-title
          padding 16px 20px 16px 20px
          display flex
          align-items center
          justify-content space-between
          color #00FFF6
          h3
            color #00FFF6
            &::before
              display inline-block
              content ''
              margin-right 12px
              width 4px
              height 12px
              background rgba(0,236,235,1)
              border-radius 2px
        .table-main
          padding 0 15px
          // background rgba(0, 71, 168, 0.85)
          .row-item
            display flex
            justify-content space-between
            padding 10px 8px
            text-align center
            transition all .3s linear
            .province-box
              width 120px
            .num-box
              width 70px
            .bz-box
              width 70px
            .bz-num-box
              position relative
              width 120px
              .iconfont
                position absolute
                right 0
            &:first-child
              padding 13px 0
              background #042E74
              border-radius 4px
            &:nth-child(odd)
              background rgba(0,42,111,0.6)
              border-radius 4px
            &.row-hover:hover
              cursor pointer
              background rgba(76, 134, 228, 0.6)
              transition all .3s linear
          &.p-main
            width 668px
            .row-item
              .item-box
                &:nth-child(1)
                  width 140px
                &:nth-child(2)
                  width 80px
                &:nth-child(3)
                  width 80px
                &:nth-child(4)
                  width 80px
                &:nth-child(5)
                  width 80px
                &:nth-child(6)
                  width 80px
                &:nth-child(7)
                  width 120px
                &:last-child
                  position relative
                  .iconfont
                    position absolute
                    right 0

  .ivu-table-header thead tr th
    td(#042E74)
    color #fff
  .table-odd-row
    td
      color #fff
      background rgba(0,71,168,0.85)
  .table-even-row
    td
      td(#0c1f4b)
  .main-section
    margin 40px auto 0
    padding-bottom 80px
    width 98%
    h3
      margin-bottom 15px

  /* fade-transform */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all .5s;
  }

  .fade-transform-enter {
    opacity: 0;
    transform: translateX(250px);
  }

  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(-250px);
  }

  // /* fade-transform */
  // .fade-transform-leave-active,
  // .fade-transform-enter-active
  //   transition all .5s

  // .fade-transform-enter
  //   opacity 0
  //   transform translateX(-30px)

  // .fade-transform-leave-to
  //   opacity 0
  //   transform translateX(30px)

</style>
