const columnsP = [
  {
    title: '省份',
    key: 'province',
    align: 'center'
  },
  {
    title: '项目数量',
    key: 'totalProjects',
    align: 'center'
  },
  {
    title: '班组数量',
    key: 'totalTeams',
    align: 'center'
  },
  {
    title: '班组人员数量',
    key: 'totalTeamItem',
    align: 'center'
  }
]

const columnsT = [
  {
    title: '项目名称',
    key: 'projectName',
    align: 'center'
  },
  {
    title: '总任务量',
    key: 'totalTasks',
    align: 'center'
  },
  {
    title: '培训总人数',
    key: 'totalTaskTeamItems',
    align: 'center'
  },
  {
    title: '培训总人次',
    key: 'totalTaskTeamItemsTimes',
    align: 'center'
  },
  {
    title: '在训人次',
    key: 'totalTeamItemTimes',
    align: 'center'
  },
  {
    title: '已完成培训人次',
    key: 'finishTaskTeamItemsTimes',
    align: 'center'
  },
  {
    title: '培训完成率',
    key: 'finishRates',
    align: 'center'
  }
]

const columns = {
  columnsP,
  columnsT
}

export default columns